.swiper.mySwiper {
	width: 100%;
	height: 100%;
}

.mySwiper .swiper-slide {
	text-align: center;
	font-size: 18px;
	color: #000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	border-radius: 11px;
	max-height: 420px;
	align-items: center;
	justify-content: center;
	width: 50%;
	opacity: 0.6;
	scale: 0.9;
	transition: all 300ms ease-in-out;
}

.mySwiper .swiper-slide > * {
	user-select: none !important;
	pointer-events: none !important;
}

.mySwiper .swiper-slide-active {
	opacity: 1;
	scale: 1;
}
.mySwiper .swiper-wrapper {
	display: flex;
	align-items: center;
}

.cardCarousel::after {
	top: 0;
	left: 0;
	height: 300px !important;
	width: 300px !important;
	transform: translateY(-30%) translateX(-50%) !important;
}

.cardCarousel {
	max-width: 350px;
	min-width: 290px;
}

.mySwiper .swiper-pagination {
	bottom: 80px !important;
}

.mySwiper .swiper-pagination-bullet {
	width: 27px;
	height: 27px;
	border-radius: 50%;
	transition: all 300ms ease-in-out !important;
	transform-origin: center;
}

.mySwiper .swiper-pagination-bullet-active {
	width: 50px;
	height: 27px;
	border-radius: 30px;
	background-color: #3b3a52;
}
@media screen and (min-width: 1430px) {
	.swiper-slide {
		max-height: 370px;
	}
}

@media screen and (max-width: 850px) {
	.cardHeading {
		font-size: 14px !important;
	}
	.cardSubtitle {
		font-size: 12px !important;
	}
}

@media screen and (max-width: 767px) {
	.cardHeading {
		font-size: 20px !important;
		width: 85% !important;
	}
	.cardSubtitle {
		font-size: 16px !important;
		width: 85% !important;
	}
}

@media screen and (max-width: 650px) {
	.cardCarousel {
		max-height: 390px;
	}
}

@media screen and (max-width: 585px) {
	.cardHeading {
		font-size: 18px !important;
	}
	.cardSubtitle {
		font-size: 14px !important;
	}
}

@media screen and (max-width: 525px) {
	.mySwiper .swiper-slide {
		width: 65%;
	}
}

@media screen and (max-width: 375px) {
	.mySwiper .swiper-slide {
		width: 80%;
	}

	.cardCarousel::after {
		height: 250px !important;
		width: 250px !important;
		transform: translateY(-35%) translateX(-50%) !important;
	}

	.cardHeading {
		font-size: 20px !important;
		width: 90% !important;
	}

	.cardSubtitle {
		font-size: 15px !important;
	}

	.swiper-slide {
		max-height: 355px !important;
	}
}
