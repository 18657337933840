@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;800&family=Poppins:wght@100;300;400;500;700;800&display=swap");
body {
	margin: 0;
	font-family: Poppins;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	scroll-behavior: smooth;
	-ms-behavior: smooth;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

body::-webkit-scrollbar {
	width: 14px;
}

body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px #2a293c;
	background: #2a293c;
}

body::-webkit-scrollbar-thumb {
	background-color: #3f70f3;
	border: 2px solid #2a293c;
	border-radius: 10px;
}

#root {
	overflow-x: hidden;
	scroll-behavior: smooth;
}
