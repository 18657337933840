.swiper.swiperComments {
	width: 100%;
	height: 400px;
}

.swiperComments .swiper-slide {
	text-align: center;
	font-size: 18px;
	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper.swiperComments {
	margin-left: auto;
	margin-right: auto;
}

.swiperComments .swiper-pagination-bullet {
	width: 15px;
	height: 15px;
}

.swiperComments .swiper-button-next {
	color: #fff;
	right: 90px !important;
}
.swiperComments .swiper-button-prev {
	color: #fff;
	left: 90px !important;
}

.swiperComments .swiper-button-next::after,
.swiperComments .swiper-button-prev::after {
	font-size: 2.3rem;
}

.swiperComments .swiper-button-next:active,
.swiperComments .swiper-button-prev:active {
	opacity: 0.7;
}

.swiper.comments .swiper-pagination-bullet {
	opacity: 0.8 !important;
	background: #716f8c !important;
}

.swiperComments .swiper-pagination {
	bottom: 40px;
}

.swiper.comments .swiper-pagination-bullet-active {
	background-color: #fff !important;
}

.swiperComments .swiper-pagination-bullet-active {
	background-color: #fff !important;
}

@media screen and (min-width: 1440px) {
	.swiperComments .swiper-button-next {
		right: 200px !important;
		transform: translateY(-30%) !important;
	}
	.swiperComments .swiper-button-prev {
		left: 200px !important;
		transform: translateY(-30%) !important;
	}
}

@media screen and (min-width: 1380px) {
	.swiperComments .swiper-button-next {
		right: 90px !important;
	}
	.swiperComments .swiper-button-prev {
		left: 90px !important;
	}
}

@media screen and (min-width: 1280px) {
	.swiperComments .swiper-button-next {
		right: 50px !important;
	}
	.swiperComments .swiper-button-prev {
		left: 50px !important;
	}
}

@media screen and (min-width: 900px) {
	.swiperComments .swiper-button-next {
		right: 35px !important;
	}
	.swiperComments .swiper-button-prev {
		left: 35px !important;
	}
}

@media screen and (min-width: 760px) {
	.swiperComments .swiper-button-next {
		right: 0 !important;
	}
	.swiperComments .swiper-button-prev {
		left: 0 !important;
	}
}

@media screen and (max-width: 767px) {
	.swiper.comments {
		height: 680px;
	}

	.swiper.comments .swiper-slide {
		width: 55%;
	}
}

@media screen and (max-width: 581px) {
	.swiper.comments .swiper-slide {
		width: 70%;
	}
}

@media screen and (max-width: 481px) {
	.swiper.comments .swiper-slide {
		width: 85%;
	}

	.swiper.comments .swiper-pagination {
		bottom: 140px !important;
	}
}
